import vars from "Consts/vars";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";

const Layout = dynamic(() => import("Containers/layout"), { ssr: true });
const Image = dynamic(() => import("Components/image-fallback-next"), { ssr: false });

const Custom404 = () => {

	const authorization = Cookies.get("token") || null;
	const usrname = Cookies.get("usrname") || null;

	const getData = authorization && usrname ? useSelector(
		(state) => state.storeSelf,
	) : { status: vars.FETCH_RESULT };

	const HandleToHomepage = () => {

		window.location.href = "/continue-shopping";

	};

	return (
		<>
			<Layout
				home
				titleNavbar="Not Found"
				metaTitle="Not Found"
				typeNavbarSecondary="type-1"
				self={getData}
				isAdaptiveLoad
			>
				<section className="err-section">
					<div className="err-container">
						<div className="err-image-wrapper">
							<Image
								src="/images/not_found.png"
								alt="not found"
								className="image-product"
							/>
						</div>
						<div className="err-info">
							<h1 className="title">404 NOT FOUND</h1>
							<p className="subtitle">
								Uh oh!
								Sorry the page that you’re looking for is not found.

							</p>
							<div className="err-to-homepage" onClick={HandleToHomepage} aria-hidden="true">
								<h4>Back to homepage</h4>
							</div>
						</div>
					</div>
				</section>

			</Layout>
			<style>
				{`
          .err-image {
            width: 400px;
          }
					.err-image-wrapper{
						position: relative;
						width: 390px;
						hight: 290px;
					}
          .err-section {
            display: flex;
			justify-content: center;
			align-items: center;
            text-align: center;
            margin: 140px 0;
          }
		  .err-container{
			  display: grid;
			  grid-template-columns: auto auto;
			  gap: 80px;
		  }
		  .err-info{
			  width: 370px;
		  }
		  .navbar-secondary-1-mobile.show {
			height: 62px;
			opacity: 1;
			visibility: visible;
			width: 100vw;
		}
		.title{
			
			font-size: 38px;
			font-weight: 600;
			line-height: 46px;
			letter-spacing: 0.04em;
			text-align: left;
		}
		.subtitle{
			
			font-size: 20px;
			font-weight: 400;
			line-height: 30px;
			letter-spacing: 0.04em;
			text-align: left;
		}
		.err-to-homepage{
			
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			letter-spacing: 0.04em;
			text-align: left;
			cursor: pointer;
		}
		
      @media screen and (max-width: 500px) {
      .top-menu .wrapper,
			.search-input-wrapper,
			.header-right-menu {
				display: none;
			}
			.err-section{
				margin: 20px;
				display: flex;
				justify-content: left;
			}
			.err-container{
				display: grid;
				grid-template-columns: auto;
				gap: 10px;
				width: 200px;
			}
			img {
				width: 80%;
			}
			.bs-footer-desktop .grid {
				margin: 1em 0;
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 10px;
				counter-reset: div;
			}
          }
        `}
			</style>
		</>

	);

};

export default Custom404;
